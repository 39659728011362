.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.react-player :global(.react-player__shadow) {
  background: #0e1010 !important;
  background-opacity: 0.8 !important;
  width: 48px !important;
  height: 48px !important;
}
.react-player :global(.react-player__shadow) :global(.react-player__play-icon) {
  border-width: 12px 0px 12px 20px !important;
  margin-left: 4px !important;
}
