.ui-demo :global(#pagination-container) {
  position: static;
}

.ui-demo :global(.swiper),
.ui-demo :global(.swiper-container) {
  position: static;
  padding: theme(padding.8) theme(padding.0);
}

.ui-demo :global(.swiper-pagination) {
  position: absolute;
  bottom: theme(inset.0);
  left: 50%;
  transform: translateX(-50%);
}

.ui-demo :global(.swiper-slide-active) {
  z-index: 900;
  transform: scale(1.25);
  transition: all;
}
